export const removeEdges = (data) => {
  return data.edges.map((edge) => edge.node)
}

const RESOLUTIONS = [210, 420, 768, 1024, 1400, 1600, 1920, 2560]

const VARIANTS = {
  natural: 0.71,
  square: 1,
  landscape: 1.4,
  panoramic: 2.5,
  portrait: 0.8
}

export function generateImageVariants(image) {
  let variants = []
  Object.keys(VARIANTS).forEach((variantName) => {
    let srcset = []
    const [base, rest] = image.originalSrc.split('.jpg')
    RESOLUTIONS.forEach((resolution) => {
      const height = Math.round(resolution / VARIANTS[variantName])
      const src = {
        w: resolution,
        h: height,
        url: `${base}_${resolution}x${height}_crop_center.jpg${rest}`
      }
      srcset = [...srcset, src]
    })
    const variant = {
      name: variantName,
      aspectRatio: VARIANTS[variantName],
      srcset
    }
    variants = [...variants, variant]
  })
  return variants
}

const COLORS = [
  {
    hex: '#000000',
    name: 'black',
    isLight: false
  },
  {
    hex: '#f3f3eb',
    name: 'cream',
    isLight: true
  },
  {
    hex: '#65615c',
    name: 'grey',
    isLight: false
  },
  {
    hex: '#eae7de',
    name: 'beige',
    isLight: true
  },
  {
    hex: '#e1ab63',
    name: 'yellow',
    isLight: false
  },
  {
    hex: '#000033',
    name: 'navy',
    isLight: false
  },
  {
    hex: '#ac8874',
    name: 'natural',
    isLight: false
  },
  {
    hex: '#87acc5',
    name: 'azure',
    isLight: false
  },
  {
    hex: '#d4b288',
    name: 'gold',
    isLight: false
  },
  {
    hex: '#bcbcb4',
    name: 'silver',
    isLight: true
  },
  {
    hex: '#FFFFFF',
    name: 'white',
    isLight: true
  },
  {
    hex: '#a60e23',
    name: 'red',
    isLight: false
  },
  {
    hex: '#FFCDD2',
    name: 'pink',
    isLight: false
  },
  {
    hex: '#d4cebd',
    name: 'green',
    isLight: true
  },
  {
    hex: '#894535',
    name: 'brown',
    isLight: false
  },
  {
    hex: '#993399',
    name: 'purple',
    isLight: false
  },
  {
    hex: '#563c78',
    name: 'violet',
    isLight: false
  },
  {
    hex: '#FF8C00',
    name: 'orange',
    isLight: false
  },
  {
    hex: '#2fa5d9',
    name: 'blue',
    isLight: false
  }
]

export const mapProduct = (product) => {
  if (!product) {
    return null
  }
  const mapMedia = (media) => {
    if (media.mediaContentType === 'IMAGE') {
      return {
        type: 'IMAGE',
        alt: media.alt,
        originalSrc: media.image.originalSrc,
        id: media.image.id,
        from: 'shopify'
      }
    } else if (media.mediaContentType === 'VIDEO') {
      return {
        type: 'VIDEO',
        from: 'shopify',
        alt: media.alt,
        sources: media.sources
      }
    } else {
      return null
    }
  }
  const productVariants = removeEdges(product.variants)
  const productMedia = product.media ? removeEdges(product.media).map((item) => mapMedia(item)) : null

  // const productImages = productMedia ? productMedia.filter(media => media.type === "IMAGE") : null;
  const productImages = removeEdges(product.images).map((item) => ({ ...item, from: 'shopify', type: 'IMAGE' }))

  // console.log(productImages)

  let primaryMedia = productMedia[0] ?? null
  let secondaryMedia = productMedia[1] ?? null
  let primaryImage = productImages[0] ?? null
  let secondaryImage = productImages[1] ?? null

  let str = product.tags.find((t) => t.includes('plp-'))
  if (str) {
    str = str.replace('plp-', '')
    const primaryImageIndex = str.slice(0, str.indexOf('-')) - 1
    const secondaryImageIndex = str.slice(str.indexOf('-') + 1) - 1
    if (productMedia[primaryImageIndex]) {
      primaryMedia = productMedia[primaryImageIndex] ?? null
    }
    if (productMedia[secondaryImageIndex]) {
      secondaryMedia = productMedia[secondaryImageIndex] ?? null
    }
    if (productImages[primaryImageIndex]) {
      primaryImage = productImages[primaryImageIndex] ?? null
    }
    if (productImages[secondaryImageIndex]) {
      secondaryImage = productImages[secondaryImageIndex] ?? null
    }
  }

  const colorName = product.tags.find((tag) => tag.includes('color'))?.split('-')[1] ?? null

  const sizes = productVariants.map((variant) => ({ id: variant.title, label: variant.title })) // Imporant! We treat size as ID. This removes duplicates from filtering, makes nice URLs for size filtering etc. We don't need this ID.

  const _color = COLORS.find((c) => c.name === colorName)
  const color = {
    name: colorName,
    hex: _color ? _color.hex : 'ruby',
    isLight: _color ? _color.isLight : false
  }
  const prices = productVariants.map((variant) => variant.priceV2)
  const compareAtPrices = productVariants.map((variant) => variant.compareAtPriceV2)

  const price = prices[0]
  const compareAtPrice = compareAtPrices[0]

  const restProduct = {
    sizingHTML: product.sizingHTML?.value ?? null,
    detailsHTML: product.detailsHTML?.value ?? null,
    stylesWith: product.stylesWith?.value.split('###') ?? null,
    descriptionTag: product.descriptionTag?.value ?? null,
    preorderMessage: product.preorderMessage?.value ?? null,
    comp: product.comp?.value ?? null,
    rrp_usd: product.rrp_usd?.value ?? null,
    rrp_eur: product.rrp_eur?.value ?? null,
    rrp_gbp: product.rrp_gbp?.value ?? null,
    sizes,
    tags: product.tags,
    color,
    media: product.media ? [...productMedia.filter((e) => e.type === 'VIDEO'), ...productImages] : productImages,
    primaryImage,
    secondaryImage,
    primaryMedia,
    secondaryMedia,
    relatedProducts: [],
    price,
    compareAtPrice
  }

  return {
    ...product,
    ...restProduct,
    variants: productVariants.map((variant) => ({
      title: variant.title,
      id: variant.id,
      quantityAvailable: variant.quantityAvailable,
      sku: variant.sku,
      available: variant.availableForSale,
      size: { id: variant.title, label: variant.title },
      isLowStock: false,
      isFinalSale: false,
      color,
      productId: product.id,
      productHandle: product.handle
    }))
  }
}

export const mapHandleIfProductExists = (map, h) => {
  if (typeof map[h] === 'undefined') {
    return null
  }
  return map[h]
}

export const mapCheckoutProduct = (product) => {
  const newProduct = mapProduct(product)

  const generateVariant = (variant) => ({
    ...variant,
    size: { id: variant.title, label: variant.title },
    isLowStock: false,
    isFinalSale: false
  })
  return {
    ...newProduct,
    variants: newProduct.variants.map((variant) => ({
      ...generateVariant(variant),
      product: {
        ...newProduct,
        variants: newProduct.variants.map((v) => ({
          ...generateVariant(v)
        }))
      }
    }))
  }
}

export const mapCollection = (collection) => {
  return {
    id: collection.id,
    handle: collection.handle,
    text: collection.text,
    image: collection.image
      ? {
          ...collection.image,
          alt: collection.image.altText,
          from: 'shopify'
        }
      : null,
    isWhite: true,
    title: collection.title,
    products: removeEdges(collection.products).map(mapProduct)
  }
}
