import React from 'react'
import Image2 from '@commerce-ui/core2/Image2'
import { generateImageVariants } from '../../../data/shopify/mapHelpers'
// import createImage from "../../../helpers/createImage";

function Image(props) {
  let src = props.src || props.image

  if (src.from === 'shopify') {
    // if (props.forceImgix) {
    //
    //     //// set imgix cdn's srcset
    //
    //     const [base, rest] = src.originalSrc.split('.jpg')
    //     src = createImage({
    //         ...src,
    //         width: 2000, // assumption that images from shopify have 2000x2800 dimension
    //         height: 2800,
    //         originalSrc: base + ".jpg"
    //     })
    //
    // } else {
    //// set shopify cdn's srcset

    src = {
      ...src,
      variants: generateImageVariants(src)
    }
    // }
  }

  return <Image2 {...props} src={src} durationFadeIn={300} backgroundColor={'imageBg'} />
}

export default Image
